import './App.css';
import Map from './components/map';

const markers = [
  {
    location: { lat: 50, lng: 115 },
    content:
      "Info over bouwmarkt <br /> Zie bijvoorbeeld website: <a href='https://www.vvvf.nl/home'>VVVF</a>",
    name: 'Bouwmarkt',
  },
  {
    location: { lat: 70, lng: 163 },
    content: 'Info over deze auto <br /> Met extra uitleg of linkje etc.',
    name: 'Auto',
  },
  {
    location: { lat: 84.25, lng: 240 },
    content: 'Info over dit vliegtuig <br /> Met extra uitleg of linkje etc.',
    name: 'Vliegtuig',
  },
  {
    location: { lat: 79.5, lng: 150.9 },
    content: 'Info over deze boot <br /> Met extra uitleg of linkje etc.',
    name: 'Boot',
  },
  {
    location: { lat: 10.9, lng: 271.5 },
    content: 'Info over dit stadion <br /> Met extra uitleg of linkje etc.',
    name: 'Stadion',
  },
];

function App() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Map width={300} height={200} center={[0, 0]} markers={markers} />
    </div>
  );
}

export default App;
