import { useMap } from 'react-leaflet';
import { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet.smooth_marker_bouncing';

function MapController({ selectedMarker }) {
  const map = useMap();
  const flyToDuration = 1;

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    if (selectedMarker) {
      const flyTo = (location) => {
        if (!location) return;
        map.flyTo(location, 5, {
          animate: true,
          duration: flyToDuration,
        });
      };
      flyTo(selectedMarker.location);
    }
  }, [selectedMarker, map]);

  useEffect(() => {
    map.eachLayer(async (layer) => {
      if (
        layer instanceof L.Marker &&
        map.getBounds().contains(layer.getLatLng())
      ) {
        if (
          selectedMarker &&
          layer.getLatLng().lat === selectedMarker.location.lat &&
          layer.getLatLng().lng === selectedMarker.location.lng
        ) {
          await sleep(flyToDuration * 1000 + 100);
          layer.bounce();
        } else {
          layer.stopBouncing();
        }
      }
    });
  }, [selectedMarker, map]);

  return null;
}

export { MapController };
