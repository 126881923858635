import { useState } from 'react';
import { ImageOverlay, MapContainer, Marker, Popup } from 'react-leaflet';
import { MapController } from './mapController';

const Map = ({ width, height, zoom, center, markers }) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const wh = [height, width];
  const origin = [0, 0];
  const bounds = [origin, wh];

  const renderMarkers = () => {
    return markers.map((marker, index) => (
      <Marker
        key={index}
        position={marker.location}
        eventHandlers={{
          click: () => {
            setSelectedMarker(marker);
          },
        }}
      >
        <Popup offset={[0, -10]}>{marker.content}</Popup>
      </Marker>
    ));
  };

  const renderList = () => {
    return markers.map((marker, index) => (
      <li
        key={index}
        onClick={() => {
          setSelectedMarker(marker);
        }}
        style={{
          cursor: 'pointer',
          color: 'white',
          backgroundColor: 'black',
          padding: 20,
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        {marker.name}
      </li>
    ));
  };

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <div
        style={{
          position: 'absolute',
          marginTop: 100,
          width: 'fit-content',
          backgroundColor: 'grey',
          opacity: 0.8,
          zIndex: 1000,
        }}
      >
        {renderList()}
      </div>
      <MapContainer
        zoom={center ? zoom : undefined}
        center={zoom ? center : undefined}
        bounds={zoom ? undefined : bounds}
        boundsOptions={{
          padding: [100, 100],
        }}
        // maxBounds={[
        //   [-100, -100],
        //   [2000, 2000],
        // ]}
        maxZoom={5}
        minZoom={3}
        zoomSnap={0}
        whenReady={(e) => {
          e.target.fitBounds(bounds, { padding: [50, 50] });
          // console.log(e.target.getBounds());
        }}
      >
        <MapController selectedMarker={selectedMarker} />
        <ImageOverlay
          url={require('../assets/map.jpg')}
          bounds={bounds}
          interactive={true}
        />
        {renderMarkers()}
      </MapContainer>
    </div>
  );
};

export default Map;
